import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`How can we help you?
Search our help center...
Frequently Read Articles
How to use $crisp JavaScript SDK?
How to add the Crisp chatbox code to my website?
How to open the chatbox on click on a custom button?
How to use Triggers?
How to use the Auto-Responder plugin?
How can I automatically set user emails?
Browse All Categories
Install Crisp
How to install Crisp on your website & apps.
Developers
Documentation for techies. How to use our REST & JS APIs, how to tweak the chatbox, etc.
Customization
Adjust Crisp for your needs.
My Account
Everything related to your account: avatar, password, notifications & more.
Crisp Inbox
How to reply to your users using Crisp Inbox.
Troubleshooting
Having troubles? Find a solution there.
Crisp Campaigns
How to send targeted campaigns to your users.
Integrations
How to use Crisp integrations to external services.
Crisp Helpdesk
Create your helpdesk, and write articles to support your users.
Crisp Chatbot
Get the best of the Crisp ChatBot plugin. Automate your support and build conversational experience for your customers.
Automate
Automated Crisp to engage & target your visitors & users.
My Contacts
Do more with your Crisp contacts.
Billing & Pricing
Help with Crisp billing & pricing matters.
Not finding what you are looking for?
Chat with us or send us an email.`}</p>
    <p>{`Chat with usSend us an email
© 2019`}</p>
    <p>{`We run on Crisp Helpdesk.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      